<template>
  <div>
    <b-form-group :label="$t('Name')"
                  class="w-100"
    >
      <b-form-input v-model="scheduler.name" />
    </b-form-group>

    <b-tabs v-model="selectedMode">

      <b-tab :title="$t('ConnectWithGoogleAccount')" />

      <b-tab :title="$t('CreateNewAccount')" />

    </b-tabs>

    <sw-select :name="$t('SelectGoogleAccount')">
      <v-select v-model="googleAccount"
                :options="googleAccounts"
                label="name"
                @option:selected="selectAccount"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>
    <sw-select :name="$t('SelectGoogleAccountManager')">
      <v-select v-model="googleManagerAccount"
                :options="googleManagerAccounts"
                :disabled="!googleAccount"
                label="accountId"
                @option:selected="selectManager"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>
    <sw-select v-if="selectedMode !== 1"
               :name="$t('SelectGoogleAccountManagerClient')"
    >
      <v-select v-model="googleManagerClientAccount"
                :options="googleManagerClientAccounts"
                :disabled="!googleManagerAccount"
                label="name"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>
    <b-form-group v-else
                  :label="$t('NewClientName')"
    >
      <b-form-input v-model="googleManagerClientAccountName" />
    </b-form-group>

    <!--    <b-row>-->
    <!--      <b-col sm="12">-->
    <!--        <sw-select :name="$t('CompanyData')">-->
    <!--          <v-select-->
    <!--            v-model="item.organization"-->
    <!--            :options="companies"-->
    <!--            label="name"-->
    <!--            :filterable="false"-->
    <!--            @search="reloadOrganizations"-->
    <!--          >-->
    <!--            <template #no-options="{ search }">-->
    <!--              <span>-->
    <!--                <span v-if="search.length">-->
    <!--                  {{ $t('NoData') }}-->
    <!--                </span>-->
    <!--                <span v-else>-->
    <!--                  {{ $t('TypeToSearch') }}-->
    <!--                </span>-->
    <!--              </span>-->
    <!--            </template>-->
    <!--          </v-select>-->
    <!--        </sw-select>-->
    <!--      </b-col>-->
    <!--      <b-col lg="3">-->
    <!--        <sw-select :name="$t('vatIdType')">-->
    <!--          <v-select-->
    <!--            v-model="item.vatIdType"-->
    <!--            label="country"-->
    <!--            :options="vatIdTypeList"-->
    <!--            :clearable="false"-->
    <!--            :filter="(options, search) => filterByKey(options, search, 'country', 'countryCodes.')"-->
    <!--          >-->
    <!--            <template #selected-option="{ country, value }">-->
    <!--              <span><strong>{{ $te(`countryCodes.${country}`) ? $t(`countryCodes.${country}`) : country }}</strong> {{ value.replaceAll('_', ' ').toUpperCase() }}</span>-->
    <!--            </template>-->

    <!--            <template #option="{ country, value }">-->
    <!--              <span><strong>{{ $te(`countryCodes.${country}`) ? $t(`countryCodes.${country}`) : country }}</strong> {{ value.replaceAll('_', ' ').toUpperCase() }}</span>-->
    <!--            </template>-->

    <!--            <template #no-options="{ search }">-->
    <!--              <span>-->
    <!--                <span v-if="search.length">-->
    <!--                  {{ $t('NoData') }}-->
    <!--                </span>-->
    <!--                <span v-else>-->
    <!--                  {{ $t('TypeToSearch') }}-->
    <!--                </span>-->
    <!--              </span>-->
    <!--            </template>-->
    <!--          </v-select>-->
    <!--        </sw-select>-->
    <!--      </b-col>-->

    <!--      <b-col lg="9">-->
    <!--        &lt;!&ndash; Nip &ndash;&gt;-->
    <!--        <label>{{ $t('NIP') }}</label>-->

    <!--        <b-input-group-->
    <!--          class="mb-50"-->
    <!--        >-->
    <!--          <b-input-group-prepend v-if="nipExists">-->
    <!--            <b-button-->
    <!--              v-b-tooltip-->
    <!--              variant="outline-warning"-->
    <!--              :title="$t('TypedVatIdAlreadyExists')"-->
    <!--            >-->
    <!--              <feather-icon-->
    <!--                icon="AlertCircleIcon"-->
    <!--                size="12"-->
    <!--              />-->
    <!--            </b-button>-->
    <!--          </b-input-group-prepend>-->

    <!--          <b-form-input-->
    <!--            v-model="item.vatId"-->
    <!--            type="text"-->
    <!--            @blur="checkExistedNip"-->
    <!--          />-->

    <!--          <b-input-group-append v-if="item.vatIdType && item.vatIdType.country === 'Poland'">-->
    <!--            <b-button variant="outline-primary"-->
    <!--                      @click="downloadFromGus"-->
    <!--            >-->
    <!--              {{ $t('DownloadFromCSO') }}-->
    <!--            </b-button>-->
    <!--          </b-input-group-append>-->
    <!--        </b-input-group>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <div class="divider divider-left">
      <div class="divider-text text-primary">
        {{ $t('LoadRules') }}
        <sw-icon v-b-tooltip
                 icon="AlertCircleIcon"
                 :title="$t('LoadRulesTip')"
        />
      </div>
    </div>

    <!--    <sw-select :name="$t('AccountType')">-->
    <!--      <v-select v-model="item.topUpMode"-->
    <!--                :reduce="e => e.type"-->
    <!--                :options="accountTypes"-->
    <!--      >-->
    <!--        <template #selected-option="{ label }">-->
    <!--          {{ $t(label) }}-->
    <!--        </template>-->
    <!--        <template #option="{ label }">-->
    <!--          {{ $t(label) }}-->
    <!--        </template>-->
    <!--      </v-select>-->
    <!--    </sw-select>-->

    <div class="d-flex align-items-end">
      <b-form-group :label="$t('AddAdPriceBudget')"
                    class="w-100"
      >
        <b-form-input v-model="item.adBudgetLimit"
                      type="number"
        />
      </b-form-group>
    </div>

    <!--    <b-form-group>-->
    <!--      <b-form-checkbox v-model="item.generateProForma"-->
    <!--                       variant="primary"-->
    <!--      >-->
    <!--        {{ $t('GenerateProFormInvoice') }}-->
    <!--      </b-form-checkbox>-->
    <!--    </b-form-group>-->

    <!--    <sw-select :name="$t('ProFormInvoiceDay')">-->
    <!--      <sw-select-fpr>-->
    <!--        <flat-pickr v-model="item.proFormaExposureDay"-->
    <!--                    class="form-control"-->
    <!--                    :config="{ locale: getCalendarLocale($i18n.locale) }"-->
    <!--        />-->
    <!--      </sw-select-fpr>-->
    <!--    </sw-select>-->

    <!--    <b-form-group>-->
    <!--      <b-form-checkbox :checked="item.topUpMode === 'ONE_TIME'"-->
    <!--                       disabled-->
    <!--                       variant="primary"-->
    <!--      >-->
    <!--        {{ $t('OnceAMonethAddBugetToAccount') }}-->
    <!--      </b-form-checkbox>-->
    <!--    </b-form-group>-->

    <!--    <sw-info-alert v-if="item.topUpMode === 'ONE_TIME'"-->
    <!--                   :name="$t('GoogleAccountWillBeChargedAutomaticaly')"-->
    <!--    />-->

    <!--    <div>-->
    <!--      <b-form-group>-->
    <!--        <b-form-checkbox :checked="item.topUpMode === 'MANY_TIMES'"-->
    <!--                         disabled-->
    <!--                         variant="primary"-->
    <!--        >-->
    <!--          {{ $t('ChargeMultipleTimes') }}-->
    <!--        </b-form-checkbox>-->
    <!--      </b-form-group>-->
    <!--      <div v-if="item.topUpMode === 'MANY_TIMES'">-->
    <!--        <b-form-group-->
    <!--          class="ml-50"-->
    <!--          :label="$t('MonthChargesCount')"-->
    <!--        >-->
    <!--          <b-form-input v-model="item.monthChargesCount" />-->
    <!--        </b-form-group>-->
    <!--        <b-form-group>-->
    <!--          <b-form-checkbox v-model="item.chargeAccountAfterInvoicePaid"-->
    <!--                           variant="primary"-->
    <!--          >-->
    <!--            {{ $t('ChargeAccountAfterInvoicePaidStatus') }}-->
    <!--          </b-form-checkbox>-->
    <!--        </b-form-group>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="divider divider-left">-->
    <!--      <div class="divider-text text-primary">-->
    <!--        {{ $t('SpecificationOfProForma') }}-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <b-form-group>-->
    <!--      <template #label>-->
    <!--        {{ $t('ServiceName') }}-->
    <!--        <sw-icon v-b-tooltip-->
    <!--                 icon="AlertCircleIcon"-->
    <!--                 :title="$t('ServiceNameTip')"-->
    <!--        />-->
    <!--      </template>-->
    <!--      <b-form-input v-model="item.serviceName" />-->
    <!--    </b-form-group>-->
    <!--    <sw-select :name="$t('DateOfSellService')">-->
    <!--      <sw-select-fpr>-->
    <!--        <flat-pickr v-model="item.serviceSellDate"-->
    <!--                    class="form-control"-->
    <!--                    :config="{ locale: getCalendarLocale($i18n.locale) }"-->
    <!--        />-->
    <!--      </sw-select-fpr>-->
    <!--    </sw-select>-->
    <!--    <b-form-group :label="$t('RewardPercent')">-->
    <!--      <template #label>-->
    <!--        {{ $t('RewardPercent') }}-->
    <!--        <sw-icon v-b-tooltip-->
    <!--                 icon="AlertCircleIcon"-->
    <!--                 :title="$t('RewardPercentTip')"-->
    <!--        />-->
    <!--      </template>-->
    <!--      <b-input-group>-->
    <!--        <b-form-input v-model="item.percentageSalary"-->
    <!--                      type="number"-->
    <!--        />-->
    <!--        <b-input-group-append is-text>-->
    <!--          %-->
    <!--        </b-input-group-append>-->
    <!--      </b-input-group>-->
    <!--    </b-form-group>-->

    <!--    <b-form-group :label="$t('PaymentDueDate')">-->
    <!--      <b-form-input v-model="item.paymentDaysCount"-->
    <!--                    type="number"-->
    <!--      /></b-form-group>-->

    <!--    &lt;!&ndash;    <div v-if="item.topUpMode === 'MANY_TIMES'">&ndash;&gt;-->
    <!--    &lt;!&ndash;      <div class="divider divider-left">&ndash;&gt;-->
    <!--    &lt;!&ndash;        <div class="divider-text text-primary">&ndash;&gt;-->
    <!--    &lt;!&ndash;          {{ $t('AutoTopUpGoogleAdsAccount') }}&ndash;&gt;-->
    <!--    &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--    &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--    &lt;!&ndash;      <b-form-group :label="$t('SetUsagePercentageConditionForTopUp')">&ndash;&gt;-->
    <!--    &lt;!&ndash;        <b-input-group>&ndash;&gt;-->
    <!--    &lt;!&ndash;          <b-form-input&ndash;&gt;-->
    <!--    &lt;!&ndash;            v-model="item.chargeAccountPercentageCondition"&ndash;&gt;-->
    <!--    &lt;!&ndash;            type="number"&ndash;&gt;-->
    <!--    &lt;!&ndash;          />&ndash;&gt;-->
    <!--    &lt;!&ndash;          <b-input-group-append is-text>&ndash;&gt;-->
    <!--    &lt;!&ndash;            %&ndash;&gt;-->
    <!--    &lt;!&ndash;          </b-input-group-append>&ndash;&gt;-->
    <!--    &lt;!&ndash;        </b-input-group>&ndash;&gt;-->
    <!--    &lt;!&ndash;      </b-form-group>&ndash;&gt;-->
    <!--    &lt;!&ndash;    </div>&ndash;&gt;-->

    <!--    <div>-->
    <!--      <div class="divider divider-left">-->
    <!--        <div class="divider-text text-primary">-->
    <!--          {{ $t('SetCampaignEndDate') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    &lt;!&ndash; Określ dzień zakończenia kampanii &ndash;&gt;-->
    <!--    <b-form-group>-->
    <!--      <b-form-checkbox v-model="item.turnOffCampaign"-->
    <!--                       variant="primary"-->
    <!--      >-->
    <!--        {{ $t('DoNotAutomaticallyTurnOffCampaign') }}-->
    <!--      </b-form-checkbox>-->
    <!--    </b-form-group>-->

    <!--    <sw-select :name="$t('CampaignEndDate')">-->
    <!--      <sw-select-fpr>-->
    <!--        <flat-pickr v-model="item.accountEndDate"-->
    <!--                    class="form-control"-->
    <!--                    :config="{ locale: getCalendarLocale($i18n.locale) }"-->
    <!--        />-->
    <!--      </sw-select-fpr>-->
    <!--    </sw-select>-->

    <!--    <sw-info-alert :name="$t('InfoTooltip')" />-->

    <sw-select :name="$t('IssueFrequency')">
      <v-select v-model="scheduler.documentIssuingType"
                :options="documentIssuingTypes"
                :reduce="e => e.type"
                label="label"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group v-if="scheduler.documentIssuingType === 'DEPENDENCY'"
                  :label="$t('DocumentsPerMonthCount')"
                  class="w-100"
    >
      <b-form-input v-model="scheduler.maxPaymentsPerMonth"
                    type="number"
      />
    </b-form-group>

    <b-form-group v-if="scheduler.documentIssuingType === 'DEPENDENCY'"
                  :label="$t('DependsOnGoogleAdsBudgetUsage')"
                  class="w-100"
    >
      <b-form-input v-model="item.minBudgetUsageBeforeChargePercentage"
                    type="number"
      />
    </b-form-group>

    <sw-select :name="$t('SalesDate')">
      <v-select v-model="scheduler.serviceSellDate"
                :options="serviceSellDates"
                :reduce="e => e.type"
                disabled
                label="label"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group :label="$t('PaymentTerm')"
                  class="w-100"
    >
      <b-form-input v-model="scheduler.paymentDaysCount"
                    type="number"
      />
    </b-form-group>

    <div class="divider divider-left">
      <div class="divider-text text-primary">
        {{ $t('PaymentPositions') }}
      </div>
    </div>

    <!--    <b-button size="sm"-->
    <!--              variant="primary"-->
    <!--              @click="positionModal.show = true"-->
    <!--    >-->
    <!--      <sw-icon icon="PlusIcon" />-->
    <!--      {{ $t('Add') }}-->
    <!--    </b-button>-->

    <b-row v-for="(position, index) in scheduler.contactThreadPaymentScheduleItems"
           :key="`payment_pos_${index}`"
    >
      <b-col sm="12"
             md="6"
      >
        <b-form-group :label="$t(index === 0 ? 'BudgetOfGoogleAdsCampaign' : 'PercentRewardGoogleAds')">
          <b-form-input v-model="position.name" />
        </b-form-group>
      </b-col>
      <b-col sm="12"
             md="6"
      >
        <b-form-group :label="index === 0 ? $t('Value') : `${$t('Value')} (%)`">
          <b-form-input v-model="position.priceNet" />
        </b-form-group>
      </b-col>
    </b-row>

    <!--    <b-table-->
    <!--      :items="scheduler.contactThreadPaymentScheduleItems"-->
    <!--      :fields="positionFields"-->
    <!--      class="mt-25"-->
    <!--      striped-->
    <!--      responsive-->
    <!--      :style="{ minHeight: tableSize(item.contactThreadPaymentScheduleItems, 100) }"-->
    <!--      show-empty-->
    <!--      :no-local-sorting="true"-->
    <!--    >-->
    <!--      &lt;!&ndash;    Head    &ndash;&gt;-->
    <!--      <template #head()="{ label }">-->
    <!--        {{ $t(`${label}`) }}-->
    <!--      </template>-->

    <!--      <template #cell(priceNet)="{ value, item }">-->
    <!--        <div class="text-nowrap">-->
    <!--          1x <strong>{{ value | priceFormat }}</strong> PLN-->
    <!--        </div>-->
    <!--        <div class="text-nowrap">-->
    <!--          {{ item.quantity }}x <strong>{{ item.priceNetTotal | priceFormat }}</strong> PLN-->
    <!--        </div>-->
    <!--      </template>-->
    <!--      <template #cell(vatPercentage)="{ value }">-->
    <!--        {{ value }}%-->
    <!--      </template>-->
    <!--      <template #cell(priceGross)="{ value, item }">-->
    <!--        <div class="text-nowrap">-->
    <!--          1x <strong>{{ value | priceFormat }}</strong> PLN-->
    <!--        </div>-->
    <!--        <div class="text-nowrap">-->
    <!--          {{ item.quantity }}x <strong>{{ item.priceGrossTotal | priceFormat }}</strong> PLN-->
    <!--        </div>-->
    <!--      </template>-->
    <!--      <template #cell(quantityUnit)="{ value }">-->
    <!--        {{ $t(`unit.${value}`) }}-->
    <!--      </template>-->

    <!--      &lt;!&ndash;    Other    &ndash;&gt;-->
    <!--      <template #cell()="row">-->
    <!--        <table-default-cell-->
    <!--          :field="row.field"-->
    <!--          :value="row.value"-->
    <!--        />-->
    <!--      </template>-->

    <!--      <template #cell(action)="{ item, index }">-->
    <!--        <b-dropdown-->
    <!--          id="dropdown-grouped"-->
    <!--          dropleft-->
    <!--          no-caret-->
    <!--          variant="flat"-->
    <!--        >-->
    <!--          <template #button-content>-->
    <!--            <feather-icon icon="MoreVerticalIcon" />-->
    <!--          </template>-->
    <!--          <div>-->
    <!--            <b-dropdown-item @click="positionModal.show = true; positionModal.item = JSON.parse(JSON.stringify(item)); positionModal.index = index">-->
    <!--              <dropdown-item-label-->
    <!--                icon="EditIcon"-->
    <!--                :label="$t('Edit')"-->
    <!--              />-->
    <!--            </b-dropdown-item>-->
    <!--            <b-dropdown-item v-if="index > 1"-->
    <!--                             @click="$delete(item.contactThreadPaymentScheduleItems, index)"-->
    <!--            >-->
    <!--              <dropdown-item-label-->
    <!--                icon="TrashIcon"-->
    <!--                :label="$t('Delete')"-->
    <!--              />-->
    <!--            </b-dropdown-item>-->
    <!--          </div>-->
    <!--        </b-dropdown>-->
    <!--      </template>-->

    <!--      &lt;!&ndash;      Empty      &ndash;&gt;-->
    <!--      <template #empty>-->
    <!--        <empty-content />-->
    <!--      </template>-->

    <!--      &lt;!&ndash;      Table Busy      &ndash;&gt;-->
    <!--      <template #table-busy>-->
    <!--        <div class="text-center text-danger my-2">-->
    <!--          <b-spinner-->
    <!--            class="align-middle"-->
    <!--            variant="primary"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </b-table>-->

    <sw-select :name="$t('SelectDocumentType')">
      <v-select v-model="scheduler.documentType"
                :reduce="e => e.type"
                :options="documentTypes"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group v-if="scheduler.documentType !== 'VAT'">
      <b-form-checkbox v-model="scheduler.generateVatAfterPaid"
                       variant="primary"
      >
        {{ $t('GenerateWhenStatusPaid') }}
      </b-form-checkbox>
    </b-form-group>

    <!--    <div class="divider divider-left">-->
    <!--      <div class="divider-text text-primary">-->
    <!--        {{ $t('DocumentPositions') }}-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="d-flex align-items-center">-->
    <!--      <b-button variant="primary"-->
    <!--                size="sm"-->
    <!--                @click="item.contactThreadPaymentScheduleItems.push({ name: newService.name, price: newService.price }); newService = { name: '', price: 0 }"-->
    <!--      >-->
    <!--        {{ $t('Add') }}-->
    <!--      </b-button>-->
    <!--    </div>-->

    <!--    <div class="mb-1">-->
    <!--      <b-list-group v-if="item.contactThreadPaymentScheduleItems.length">-->
    <!--        <b-list-group-item v-for="(service, index) in item.contactThreadPaymentScheduleItems"-->
    <!--                           :key="index"-->
    <!--                           class="d-flex justify-content-between align-items-center"-->
    <!--        >-->
    <!--          <div>-->
    <!--            {{ service.name }} <b-badge>{{ service.price }}</b-badge>-->
    <!--          </div>-->
    <!--          <div>-->
    <!--            <b-button variant="flat-danger"-->
    <!--                      size="sm"-->
    <!--                      @click="$delete(item.contactThreadPaymentScheduleItems, index)"-->
    <!--            >-->
    <!--              <sw-icon icon="TrashIcon" />-->
    <!--            </b-button>-->
    <!--          </div>-->
    <!--        </b-list-group-item>-->
    <!--      </b-list-group>-->
    <!--      <div-->
    <!--        v-else-->
    <!--        class="p-1 text-center text-primary"-->
    <!--      >-->
    <!--        <feather-icon icon="XCircleIcon" />-->
    <!--        {{ $t('NoData') }}-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- Określ dzień zakończenia kampanii -->
    <b-form-group>
      <b-form-checkbox v-model="scheduler.turnOffSchedule">
        {{ $t('SpecifyEndScheduleDate') }}
      </b-form-checkbox>
    </b-form-group>

    <b-row>
      <b-col sm="12"
             md="6"
      >
        <sw-select :name="$t('SpecifyStartDocumentDate')">

          <sw-select-fpr>
            <flat-pickr v-model="scheduler.scheduleStartDate"
                        class="form-control"
                        :config="{ locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>

      <b-col v-if="scheduler.turnOffSchedule"
             sm="12"
             md="6"
      >
        <sw-select v-if="scheduler.turnOffSchedule"
                   :name="$t('EndScheduleDate')"
        >
          <sw-select-fpr>
            <flat-pickr v-model="scheduler.scheduleEndDate"
                        class="form-control"
                        :config="{ locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>
    </b-row>

    <sw-info-alert v-if="scheduler.turnOffSchedule"
                   :name="$t('ScheduleEndInfo')"
    />

    <div>
      <sw-action-button ref="save-action"
                        label="Save"
                        siz="sm"
                        variant="primary"
                        @click="saveItem"
      />
    </div>

    <b-modal v-model="positionModal.show"
             hide-footer
             :title="$t(positionModal.index > -1 ? 'Edit' : 'AddItem')"
             @hidden="positionModal.show = false; positionModal.item = JSON.parse(JSON.stringify(defaultPosition)); positionModal.index = -1"
    >
      <b-form-group :label="$t('Name')">
        <b-form-input
          v-model="positionModal.item.name"
        />
      </b-form-group>

      <!--      <sw-select :name="$t('Unit')">-->
      <!--        <v-select-->
      <!--          v-model.trim="positionModal.item.quantityUnit"-->
      <!--          :options="units"-->
      <!--          :get-option-label="name => $t(`unit.${name}`)"-->
      <!--        />-->
      <!--      </sw-select>-->

      <!--      <b-form-group :label="$t('Offer.Amount')">-->
      <!--        <b-form-input-->
      <!--          v-model="positionModal.item.quantity"-->
      <!--          type="number"-->
      <!--          placeholder="0"-->
      <!--        />-->
      <!--      </b-form-group>-->

      <b-form-group :label="$t('Offer.NetPrice')">
        <b-form-input
          v-model="positionModal.item.priceNet"
          type="number"
          placeholder="0"
        />
      </b-form-group>

      <!--      <sw-select name="Vat">-->
      <!--        <v-select-->
      <!--          v-model="positionModal.item.vatPercentage"-->
      <!--          :options="[0, 8, 23]"-->
      <!--          :clearable="false"-->
      <!--        />-->
      <!--      </sw-select>-->

      <!--      <b-button-->
      <!--        type="button"-->
      <!--        variant="primary"-->
      <!--        size="sm"-->
      <!--        @click="savePositionItem"-->
      <!--      >-->
      <!--        {{ $t('Save') }}-->
      <!--      </b-button>-->
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  VBTooltip,
} from 'bootstrap-vue'
import currency from '@/helpers/currency'
import flatPickr from 'vue-flatpickr-component'
import keysCuter from '@/helpers/keysCuter'
import axiosIns from '@/libs/axios'
import units from '@/helpers/productUnits'

const defaultPosition = {
  name: '',
  priceGross: 0,
  priceGrossTotal: 0,
  priceNet: 0,
  priceNetTotal: 0,
  quantity: 1,
  quantityUnit: 'PIECE',
  vatPercentage: 23,
}

const blankSchedule = {
  name: '',
  type: 'GOOGLE_ADS',
  documentType: 'PRO_FORMA',
  // netPrice: '',
  // netPriceCurrency: 'PLN',
  generateVatAfterPaid: false,
  scheduleStartDate: '',
  documentIssuingType: '',
  maxPaymentsPerMonth: '',
  serviceSellDate: 'CURRENT_MONTH',
  paymentDaysCount: '7',
  contactThreadPaymentScheduleItems: [
    { ...JSON.parse(JSON.stringify(defaultPosition)), name: 'Budżet kamapni Google Ads' },
    { ...JSON.parse(JSON.stringify(defaultPosition)), name: 'Wynagrodzenie' },
  ],
  turnOffSchedule: false,
  scheduleEndDate: '',
  status: 'ACTIVE',
}

export default {
  name: 'AddGoogleAdsForm',
  components: {
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    editedItem: {
      default: null,
      required: true,
    },
  },

  data: () => ({
    units,
    defaultPosition,
    positionModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultPosition)),
      index: -1,
    },

    positionFields: [
      { key: 'name', label: 'Name' },
      { key: 'priceNet', label: 'Offer.NetPrice' },
      // { key: 'vatPercentage', label: 'Vat' },
      // { key: 'priceGross', label: 'Offer.GrossPrice' },
      // { key: 'quantity', label: 'Offer.Amount' },
      // { key: 'quantityUnit', label: 'Unit' },
      { key: 'action', label: 'Action' },
    ],

    blankSchedule,
    scheduler: JSON.parse(JSON.stringify(blankSchedule)),
    // item: {
    //   googleAccounts: [],
    //   name: '',
    //   currency: 'PLN',
    //   type: 'STATIC',
    // },
    currencyList: currency(),

    documentTypes: [
      { type: 'PRO_FORMA', label: 'ProFormaInvoice' },
      { type: 'VAT', label: 'VatInvoice' },
    ],

    documentIssuingTypes: [
      { type: 'MONTHLY', label: 'OncePerMonth' },
      { type: 'DEPENDENCY', label: 'DependsOnGoogleAdsBudgetUsage' },
    ],
    serviceSellDates: [
      { type: 'CURRENT_MONTH', label: 'CurrentMonth' },
    ],

    accountTypes: [
      { type: 'ONE_TIME', label: 'OncePerMonth' },
      { type: 'MANY_TIMES', label: 'ManyTimesAMonth' },
    ],

    googleAccounts: [],
    googleAccount: '',
    googleManagerAccounts: [],
    googleManagerAccount: '',
    googleManagerClientAccounts: [],
    googleManagerClientAccount: '',
    googleManagerClientAccountName: '',

    selectedMode: 0,

    item: {
      id: null,
      name: '',
      topUpMode: 'ONE_TIME',
      adBudgetLimit: '',
      generateProForma: false,
      proFormaExposureDay: '',
      serviceName: '',
      serviceSellDate: '',
      percentageSalary: 0,
      minBudgetUsageBeforeChargePercentage: 0,
      turnOffCampaign: false,
      accountEndDate: '',
      // chargeAccountPercentageCondition: '',
      chargeAccountAfterInvoicePaid: false,
      monthChargesCount: 2,
      paymentDaysCount: 7,
      adBudgetLimitCurrency: 'PLN',
    },
  }),

  watch: {
    editedItem: {
      deep: true,
      handler() {
        this.initEditedItem()
      },
    },
  },
  async mounted() {
    this.item.adBudgetLimitCurrency = this.$store.getters['system/getSettings'].defaultCurrency || 'PLN'
    await this.loadGoogleAccount()

    this.initEditedItem()
  },

  methods: {
    selectAccount(e) {
      if (e) {
        this.googleManagerAccounts = e?.integrationGoogleConnectionGoogleAdsAccounts || []
      } else {
        this.googleManagerAccounts = []
        this.googleManagerClientAccounts = []
      }
    },
    async selectManager(e) {
      if (e) {
        const resp = await axiosIns.get(`1/integration/google/connections/${this.googleAccount.id}/googleAdsAccounts/${e.id}/clients`)
        this.googleManagerClientAccounts = resp?.data?.data
      } else {
        this.googleManagerClientAccounts = []
      }
    },
    async loadGoogleAccount() {
      try {
        const response = await axiosIns.get('1/integration/google/connections?fields_load=GOOGLE')

        if (response.data?.data?.items) {
          this.googleAccounts = response.data.data.items || []
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async initEditedItem() {
      if (this.editedItem) {
        Object.entries(this.editedItem).forEach(([key, value]) => {
          if (value?.date) {
            this.$set(this.item, key, value.date)
          } else {
            this.$set(this.item, key, value)
          }
        })

        if (this.editedItem.contactThreadPaymentSchedule) {
          Object.entries(this.editedItem.contactThreadPaymentSchedule).forEach(([key, value]) => {
            if (key === 'contactThreadPaymentScheduleItems') {
              this.$set(this.scheduler, key, this.getPositionsPayload(value.contactThreadPaymentScheduleItems, true))
            } else if (value?.date) {
              if (key === 'scheduleEndDate') {
                this.$set(this.scheduler, 'turnOffSchedule', true)
              }
              this.$set(this.scheduler, key, value.date)
            } else {
              this.$set(this.scheduler, key, value)
            }
          })
        }
      }

      if (this.editedItem?.integrationGoogleConnectionGoogleAdsAccount?.integrationGoogleConnection) {
        this.googleAccount = this.editedItem?.integrationGoogleConnectionGoogleAdsAccount?.integrationGoogleConnection

        this.selectAccount(this.googleAccounts.findWhere('id', this.editedItem?.integrationGoogleConnectionGoogleAdsAccount?.integrationGoogleConnection.id))

        this.googleManagerAccount = this.editedItem?.integrationGoogleConnectionGoogleAdsAccount
        if (this.googleManagerAccount) {
          await this.selectManager(this.googleManagerAccount)

          if (this.googleManagerClientAccounts?.length) {
            this.googleManagerClientAccount = this.googleManagerClientAccounts.findWhere('id', this.editedItem.googleAdsClientId)
          } else {
            this.googleManagerClientAccount = this.editedItem.googleAdsClientId
          }
        }
      }
    },
    getSchedulerPayload() {
      const copy = JSON.parse(JSON.stringify(this.scheduler))

      // copy.netPrice = parseFloat(copy.netPrice)
      copy.paymentDaysCount = parseFloat(copy.paymentDaysCount)
      copy.scheduleStartDate = parseFloat(copy.scheduleStartDate)
      copy.maxPaymentsPerMonth = parseFloat(copy.maxPaymentsPerMonth)

      copy.contactThread = this.$route.params.threadId

      if (!copy.turnOffSchedule) {
        copy.scheduleEndDate = null
      }

      copy.contactThreadPaymentScheduleItems = this.getPositionsPayload(copy.contactThreadPaymentScheduleItems.map(e => ({
        ...e,
        name: e.name,
        priceGross: Number(e.priceGross),
        priceGrossTotal: Number(e.priceGrossTotal),
        priceNet: Number(e.priceNet),
        priceNetTotal: Number(e.priceNetTotal),
        quantity: Number(e.quantity),
        quantityUnit: e.quantityUnit,
        vatPercentage: Number(e.vatPercentage),
      })))

      return copy
    },
    getPositionsPayload(positions = [], init = false) {
      if (init) {
        const t = positions[0]

        const p2 = positions[1]
        p2.priceNet = parseFloat(((p2.priceNet / t.priceNet) * 100).toFixed(2))
        p2.priceGrossTotal = parseFloat(((p2.priceGrossTotal / t.priceGrossTotal) * 100).toFixed(2))
        p2.priceNetTotal = parseFloat(((p2.priceNetTotal / t.priceNetTotal) * 100).toFixed(2))
        p2.priceGross = parseFloat(((p2.priceGross / t.priceGross) * 100).toFixed(2))

        return [t, p2]
      }

      const t = positions[0]
      const netPrice = parseFloat(parseFloat(t.priceNet).toFixed(2))
      const totalNetPrice = parseFloat(parseFloat((Number(t.priceNet) * Number(t.quantity))).toFixed(2))
      const totalGrossPrice = parseFloat(parseFloat(totalNetPrice * (1 + Number(t.vatPercentage) / 100)).toFixed(2))
      const grossPrice = parseFloat(parseFloat(t.priceNet * (1 + Number(t.vatPercentage) / 100)).toFixed(2))
      t.priceGrossTotal = totalGrossPrice
      t.priceNetTotal = totalNetPrice
      t.priceGross = grossPrice

      const p2 = positions[1]
      const percent = Number(p2.priceNet)
      p2.priceNet = parseFloat(((netPrice * percent) / 100).toFixed(2))
      p2.priceGrossTotal = parseFloat(((t.priceGrossTotal * percent) / 100).toFixed(2))
      p2.priceNetTotal = parseFloat(((t.priceNetTotal * percent) / 100).toFixed(2))
      p2.priceGross = parseFloat(((t.priceGross * percent) / 100).toFixed(2))

      return [t, p2]
    },
    // savePositionItem() {
    //   const itemCopy = JSON.parse(JSON.stringify(this.positionModal.item))
    //
    //
    //
    //   this.$set(this, 'positionModal', {
    //     show: false,
    //     item: JSON.parse(JSON.stringify(this.defaultPosition)),
    //     index: -1,
    //   })
    // },
    async saveItem() {
      this.$refs['save-action'].setLoading(true)
      const copy = JSON.parse(JSON.stringify(this.item))

      keysCuter(copy)

      copy.adBudgetLimit = Number(copy.adBudgetLimit || 0)
      copy.paymentDaysCount = Number(copy.paymentDaysCount || 0)
      copy.percentageSalary = Number(copy.percentageSalary || 0)
      copy.minBudgetUsageBeforeChargePercentage = Number(copy.minBudgetUsageBeforeChargePercentage || 0)
      // copy.chargeAccountPercentageCondition = Number(copy.chargeAccountPercentageCondition || 0)

      copy.contactThread = this.$route.params.threadId

      if (this.selectedMode === 1) {
        // Create Account
        const createAccountPayload = {
          name: this.googleManagerClientAccountName,
        }
        const createAccountResponse = await axiosIns.post(`1/integration/google/connections/${this.googleAccount.id}/googleAdsAccounts/${this.googleManagerAccount.id}/clients`, createAccountPayload)
        copy.googleAdsClientId = String(createAccountResponse?.data?.data?.id)
      } else {
        copy.googleAdsClientId = String(this.getObjectId(this.googleManagerClientAccount))
      }

      copy.integrationGoogleConnectionGoogleAdsAccount = this.getObjectId(this.googleManagerAccount)
      copy.contactThreadPaymentSchedule = this.getSchedulerPayload()

      console.log(copy)
      if (!copy?.id) delete copy.id
      try {
        const resp = copy?.id ? await axiosIns.patch('1/contacts/threads/googleAdsClientAccounts', { ...copy }) : await axiosIns.post('1/contacts/threads/googleAdsClientAccounts', { ...copy, status: 'ACTIVE' })
        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.$emit('save', { response: resp, item: copy })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.$refs['save-action'].setLoading(false)
      }
    },
  },
}
</script>

<style scoped>

</style>
